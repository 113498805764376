

















import { RouterNames } from "@/router/routernames";
import { MenuItem } from "@/components/Menu/menu-item";
import { MessageService } from "@/shared/services/message-service";
import menu_optionModule from "@/store/modules/menu_option-module";
import { UtilsString } from "@/utils/utils-string";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {
    DynamicTable: () => import("@/views/dynamic_tables/dynamic_table.vue"),
  },
})
export default class MenuLista extends Vue {
  nuevo() {
    this.abrir_formulario("-1");
  }

  editar(obj: MenuItem) {
    this.abrir_formulario(UtilsString.ValueOf(obj.id));
  }

  eliminar(obj: MenuItem) {
    menu_optionModule
      .eliminarmenu_option(new MenuItem(obj))
      .then(() => {
        //@ts-ignore
        this.$refs["data_table"].refresh();
      })
      .catch(() => {
        MessageService.toast(
          "No se ha podido eliminar, hay datos asociados a este grupo de usuarios",
          MessageService.TypeWarning
        );
      });
  }

  abrir_formulario(id: string) {
    this.$router.push({
      name: RouterNames.menu_plataforma_formulario,
      params: { id: id },
    });
  }
}
